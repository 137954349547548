@font-face {
  font-family: "Fellix";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../font/Fellix-Medium.woff2') format('woff2'), url('../font/Fellix-Medium.woff') format('woff');
}
@font-face {
  font-family: "Fellix";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../font/Fellix-MediumItalic.woff2') format('woff2'), url('../font/Fellix-MediumItalic.woff') format('woff');
}
@font-face {
  font-family: "Fellix";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../font/Fellix-Regular.woff2') format('woff2'), url('../font/Fellix-Regular.woff') format('woff');
}
@font-face {
  font-family: "Fellix";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../font/Fellix-RegularItalic.woff2') format('woff2'), url('../font/Fellix-RegularItalic.woff') format('woff');
}
@font-face {
  font-family: "Fellix";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../font/Fellix-SemiBold.woff2') format('woff2'), url('../font/Fellix-SemiBold.woff') format('woff');
}
@font-face {
  font-family: "Fellix";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../font/Fellix-SemiBoldItalic.woff2') format('woff2'), url('../font/Fellix-SemiBoldItalic.woff') format('woff');
}
